* {
    font-family: Arial, Helvetica, sans-serif;
}

#mainContainer {
    max-width: 1200px;
    margin: auto;
}

body {
    background-color: #ededed;
}

#pageHeader {
    text-align: center;
}

#badgeForm {
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}

#badgeForm > input {
    width: 200px;
    background-color: white;
}

#badgeForm > textarea {
    height: 15px;
    width: 250px;
}

#badgeHeader, #aboutHeader, #description {
    text-align: center;
}

.badgeContainer {
    border: 1px solid black;
    border-radius: 22px;
    width: 80%;
    max-width: 700px;
    margin: 3% auto;
}

#badgeHeaderContainer {
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}



#description {
    width: 80%;
    margin: 10px auto;
}

.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav a {
    margin-top: 10px;
    padding: 8px 32px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.hamburger {
    width: 35px;
    height: 5px;
    background-color: black;
    margin: 6px 0;
}

#navigation {
    position: absolute;
    z-index: 2;
    margin: 0px 10px;
}

@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

@media only screen and (max-width: 615px) {
    #badgeHeader {
        margin-top: 200px;
    }
    #badgeForm > input {
        width: 100%;
        height: 25px;
        margin: 5px;
    }
    
    #badgeForm > textarea {
        height: 25px;
        margin: 5px;
        width: 100%;
    }

    #badgeForm > button {
        margin-left: 5px;
        width: 100%;
        height: 25px;
    }
    #infoContainer, #aboutContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;

    }
    #infoContainer > p, #aboutContainer > p {
        margin: 10px auto;
    }
}

@media only screen and (min-width: 616px) {
    #badgeHeader {
        margin-top: 125px;
    }
    #badgeForm > input {
        width: 47%;
        margin: 5px;
        height: 25px;
    }
    
    #badgeForm > textarea {
        height: 25px;
        width: 47%;
        margin: 5px;
    }

    #badgeForm > button {
        margin-right: 5px;
    }
    #infoContainer, #aboutContainer {
        display: flex;
        justify-content: space-around;
    }
}